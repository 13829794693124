import {axiosInstance} from "./AxiosInstance";
import queryString from "query-string";
import {axiosNode1Instance} from "./AxiosNode1Instance";

const listFBPages = async () => {
  const pages = (await axiosInstance.get("/social_query/facebook/pages")).data;
  return pages;
};

const listFBGroups = async () => {
  const groups = (await axiosInstance.get("/social_query/facebook/groups")).data;
  return groups;
};

const listWpComSites = async () => {
  const sites = (await axiosInstance.get("/social_query/wordpress/sites")).data;
  return sites;
};

const listGMBLocations = async () => {
  const groups = (await axiosInstance.get("/social_query/gmb/list_locations")).data;
  return groups;
};

const listInstagramAccounts = async () => {
  const accounts = (await axiosInstance.get("/social_query/instagram/accounts")).data;
  return accounts;
};

const listLinkedinPages = async () => {
  const pages = (await axiosInstance.get("/social_query/linkedin/pages")).data;
  return pages;
};

const listChannels = async (search) => {
  const channels = (await axiosInstance.get(`/channels?${queryString.stringify(search)}`)).data;
  return channels;
};

const listScheduledPosts = async (search) => {
  const channels = (await axiosInstance.get(`/scheduled_posts?${queryString.stringify(search)}`)).data;
  return channels;
};

const countScheduledPosts = async (search) => {
  const data = (await axiosInstance.get(`/scheduled_posts/count?${queryString.stringify(search)}`)).data;
  return data;
};

const getScheduledPostById = async (scheduledPostId) => {
  return (await axiosInstance.get(`/scheduled_posts/${scheduledPostId}`)).data;
};

const deleteChannel = async (channelId) => {
  await axiosInstance.delete(`/channels/${channelId}`);
};

const deleteScheduledPost = async (scheduledPostId) => {
  await axiosInstance.delete(`/scheduled_posts/${scheduledPostId}`);
};

const deleteScheduledPosts = async (scheduledPostIds) => {
  await axiosInstance.post(`/scheduled_posts/delete_multi`, {scheduled_post_ids: scheduledPostIds});
};

const copyScheduledPostsToCollection = async (scheduledPostIds, postCollectionId) => {
  await axiosInstance.post(`/scheduled_posts/copy_to_collection`, {
    scheduled_post_ids: scheduledPostIds,
    post_collection_id: postCollectionId
  });
};

const copyScheduledPostsToDraft = async (payload) => {
  return (await axiosInstance.post("/scheduled_posts/copy_to_draft", payload)).data;
};

const setScheduledPostsSchedule = async (scheduledPostIds, schedulePayload) => {
  await axiosInstance.post(`/scheduled_posts/set_schedule`, {
    scheduled_post_ids: scheduledPostIds,
    set_payload: schedulePayload
  });
};

const addScheduledPost = async (text, channel_id, channel_ids_for_form, post_tag_ids, post_collection_id, is_recur, recur_interval, recur_interval_time_slots, recur_interval_weekly_time_slots, recur_until_dt_enabled, recur_until_dt, image_objects, gif_object, video_object, schedule_at, state, schedule_in_the_future, videoThumbnailUploadcareUuid, videoThumbnailOffset, thumbnailIds, pinterest_settings, wordpress_settings, youtube_settings, instagram_settings, facebook_settings, tiktok_settings, gmb_settings, timezone, recur_rephrase_text_with_ai, recur_rephrase_text_with_ai_tone, recurGenerateNewAiImage, recurGenerateNewAiImageModel, utm_source, utm_medium, utm_campaign, utm_term, utm_content) => {
  const payload = {
    text: text,
    channel_id: channel_id,
    channel_ids_for_form: channel_ids_for_form,
    post_tag_ids: post_tag_ids,
    is_recur: is_recur,
    recur_interval: recur_interval,
    recur_interval_time_slots: recur_interval_time_slots,
    recur_interval_weekly_time_slots: recur_interval_weekly_time_slots,
    recur_until_dt: recur_until_dt,
    recur_until_dt_enabled: recur_until_dt_enabled,
    image_objects: image_objects,
    gif_object: gif_object,
    video_object: video_object,
    state: state,
    schedule_in_the_future: schedule_in_the_future,
    pinterest_settings: pinterest_settings,
    wordpress_settings: wordpress_settings,
    video_thumbnail_uploadcare_uuid: videoThumbnailUploadcareUuid,
    video_thumbnail_offset: videoThumbnailOffset,
    video_thumbnail_ids: thumbnailIds,
    youtube_settings: youtube_settings,
    tiktok_settings: tiktok_settings,
    instagram_settings: instagram_settings,
    facebook_settings: facebook_settings,
    gmb_settings: gmb_settings,
    post_collection_id: post_collection_id,
    timezone: timezone,
    recur_rephrase_text_with_ai: recur_rephrase_text_with_ai,
    recur_rephrase_text_with_ai_tone: recur_rephrase_text_with_ai_tone,
    recur_generate_new_ai_image: recurGenerateNewAiImage,
    recur_generate_new_ai_image_model: recurGenerateNewAiImageModel,
    utm_source: utm_source,
    utm_medium: utm_medium,
    utm_campaign: utm_campaign,
    utm_term: utm_term,
    utm_content: utm_content
  };
  if (schedule_at) {
    payload.schedule_at = schedule_at;
  }
  return (await axiosInstance.post("/scheduled_posts", payload)).data;
};


const updateScheduledPost = async (scheduledPostId, text, channel_id, channel_ids_for_form, post_tag_ids, post_collection_id, is_recur, recur_interval, recur_interval_time_slots, recur_interval_weekly_time_slots, recur_until_dt_enabled, recur_until_dt, image_objects, gif_object, video_object, schedule_at, state, schedule_in_the_future, videoThumbnailUploadcareUuid, videoThumbnailOffset, thumbnailIds, pinterest_settings, wordpress_settings, youtube_settings, instagram_settings, facebook_settings, tiktok_settings, gmb_settings, timezone, recur_rephrase_text_with_ai, recur_rephrase_text_with_ai_tone, recurGenerateNewAiImage, recurGenerateNewAiImageModel, utm_source, utm_medium, utm_campaign, utm_term, utm_content) => {
  const payload = {};
  if (text !== null) {
    payload.text = text;
  }
  if (channel_id) {
    payload.channel_id = channel_id;
  }
  if (channel_ids_for_form) {
    payload.channel_ids_for_form = channel_ids_for_form;
  }
  if (post_tag_ids) {
    payload.post_tag_ids = post_tag_ids;
  }
  if (is_recur !== null) {
    payload.is_recur = is_recur;
  }
  if (recur_interval) {
    payload.recur_interval = recur_interval;
  }
  if (recur_interval_time_slots) {
    payload.recur_interval_time_slots = recur_interval_time_slots;
  }
  if (recur_interval_weekly_time_slots) {
    payload.recur_interval_weekly_time_slots = recur_interval_weekly_time_slots;
  }
  if (recur_until_dt_enabled !== null) {
    payload.recur_until_dt_enabled = recur_until_dt_enabled;
  }
  if (recur_until_dt !== null) {
    payload.recur_until_dt = recur_until_dt;
  }
  if (image_objects) {
    payload.image_objects = image_objects;
  }
  if (video_object) {
    payload.video_object = video_object;
  }
  if (gif_object) {
    payload.gif_object = gif_object;
  }
  if (schedule_at) {
    payload.schedule_at = schedule_at;
  }
  if (state) {
    payload.state = state;
  }
  if (schedule_in_the_future !== undefined && schedule_in_the_future !== null) {
    payload.schedule_in_the_future = schedule_in_the_future;
  }
  if (pinterest_settings) {
    payload.pinterest_settings = pinterest_settings;
  }
  if (wordpress_settings) {
    payload.wordpress_settings = wordpress_settings;
  }
  if (videoThumbnailUploadcareUuid) {
    payload.video_thumbnail_uploadcare_uuid = videoThumbnailUploadcareUuid;
  }
  if (videoThumbnailOffset) {
    payload.video_thumbnail_offset = videoThumbnailOffset;
  }
  if (thumbnailIds) {
    payload.video_thumbnail_ids = thumbnailIds;
  }
  if (youtube_settings) {
    payload.youtube_settings = youtube_settings;
  }
  if (tiktok_settings) {
    payload.tiktok_settings = tiktok_settings;
  }
  if (instagram_settings) {
    payload.instagram_settings = instagram_settings;
  }
  if (facebook_settings) {
    payload.facebook_settings = facebook_settings;
  }
  if (gmb_settings) {
    payload.gmb_settings = gmb_settings;
  }
  if (post_collection_id) {
    payload.post_collection_id = post_collection_id;
  }
  if (timezone) {
    payload.timezone = timezone;
  }
  if (recur_rephrase_text_with_ai) {
    payload.recur_rephrase_text_with_ai = recur_rephrase_text_with_ai;
  }
  if (recur_rephrase_text_with_ai_tone) {
    payload.recur_rephrase_text_with_ai_tone = recur_rephrase_text_with_ai_tone;
  }
  if (utm_source) {
    payload.utm_source = utm_source;
  }
  if (utm_medium) {
    payload.utm_medium = utm_medium;
  }
  if (utm_campaign) {
    payload.utm_campaign = utm_campaign;
  }
  if (utm_term) {
    payload.utm_term = utm_term;
  }
  if (utm_content) {
    payload.utm_content = utm_content;
  }
  if (recurGenerateNewAiImage) {
    payload.recur_generate_new_ai_image = recurGenerateNewAiImage;
  }
  if (recurGenerateNewAiImageModel) {
    payload.recur_generate_new_ai_image_model = recurGenerateNewAiImageModel;
  }
  return (await axiosInstance.put(`/scheduled_posts/${scheduledPostId}`, payload)).data;
};

const updateScheduledPostV2 = async (id, payload) => {
  const response = await axiosInstance.put(`/scheduled_posts/${id}`, payload);
  return response.data;
};

const addChannel = async (social_object_id, social_object_type, name, logo_url, social_network, metadata, from_channel_refresh = false) => {
  const payload = {
    social_object_type: social_object_type,
    name: name,
    logo_url: logo_url,
    social_network: social_network,
    metadata: metadata
  };
  if (social_object_id) {
    payload.social_object_id = social_object_id;
  }
  const params = {
    from_channel_refresh: from_channel_refresh
  }
  const response = await axiosInstance.post(`/channels?${queryString.stringify(params)}`, payload);
  return response.data;
};

const updateChannel = async (id, payload) => {
  const channel = (await axiosInstance.put(`/channels/${id}`, payload)).data;
  return channel;
};

const getChannel = async (id) => {
  const channel = (await axiosInstance.get(`/channels/${id}`)).data;
  return channel;
};

const addChannelWordpress = async (wordpress_url, wordpress_username, wordpress_password) => {
  const payload = {
    wordpress_url: wordpress_url,
    wordpress_username: wordpress_username,
    wordpress_password: wordpress_password
  };
  const response = await axiosInstance.post("/channels/wordpress", payload);
  return response.data;
};

const addChannelBluesky = async (bluesky_username, bluesky_password) => {
  const payload = {
    bluesky_username: bluesky_username,
    bluesky_password: bluesky_password
  };
  const response = await axiosInstance.post("/channels/bluesky", payload);
  return response.data;
};

const listWordpressChannelCategories = async (channel_id) => {
  const response = await axiosInstance.get(`/social_query/wordpress/${channel_id}/list_categories`);
  return response.data;
};

const listWordpressComChannelCategories = async (channel_id) => {
  const response = await axiosInstance.get(`/social_query/wordpress_com/${channel_id}/list_categories`);
  return response.data;
};

const listWordpressChannelPages = async (channel_id, searchText, skip, limit) => {
  let q = {
    skip: skip,
    limit: limit
  };
  if (searchText) {
    q.search_text = searchText;
  }
  const response = await axiosInstance.get(`/social_query/wordpress/${channel_id}/list_pages`, {params: q});
  return response.data;
};

const listWordpressComChannelPages = async (channel_id, searchText, skip, limit) => {
  let q = {
    skip: skip,
    limit: limit
  };
  if (searchText) {
    q.search_text = searchText;
  }
  const response = await axiosInstance.get(`/social_query/wordpress_com/${channel_id}/list_pages`, {params: q});
  return response.data;
};

const listWordpressChannelPosts = async (channel_id, searchText, skip, limit) => {
  let q = {
    skip: skip,
    limit: limit
  };
  if (searchText) {
    q.search_text = searchText;
  }
  const response = await axiosInstance.get(`/social_query/wordpress/${channel_id}/list_posts`, {params: q});
  return response.data;
};

const getWordpressChannelPost = async (channel_id, post_id) => {
  const response = await axiosInstance.get(`/social_query/wordpress/${channel_id}/get_post/${post_id}`);
  return response.data;
};

const getWordpressChannelPage = async (channel_id, page_id) => {
  const response = await axiosInstance.get(`/social_query/wordpress/${channel_id}/get_page/${page_id}`);
  return response.data;
};


const getWordpressChannelMedia = async (channel_id, media_id) => {
  const response = await axiosInstance.get(`/social_query/wordpress/${channel_id}/get_media/${media_id}`);
  return response.data;
};

const updateWordpressChannelPost = async (channel_id, post_id, payload) => {
  const response = await axiosInstance.put(`/social_query/wordpress/${channel_id}/update_post/${post_id}`, payload);
  return response.data;
};

const previewWordpressChannelPost = async (channel_id, payload) => {
  const response = await axiosInstance.put(`/social_query/wordpress/${channel_id}/preview_post`, payload);
  return response.data;
};

const updateWordpressChannelPage = async (channel_id, page_id, payload) => {
  const response = await axiosInstance.put(`/social_query/wordpress/${channel_id}/update_page/${page_id}`, payload);
  return response.data;
};

const previewWordpressChannelPage = async (channel_id, payload) => {
  const response = await axiosInstance.put(`/social_query/wordpress/${channel_id}/preview_page`, payload);
  return response.data;
};

const deleteWordpressChannelPost = async (channel_id, post_id) => {
  const response = await axiosInstance.delete(`/social_query/wordpress/${channel_id}/delete_post/${post_id}`);
  return response.data;
};

const deleteWordpressChannelPage = async (channel_id, page_id) => {
  const response = await axiosInstance.delete(`/social_query/wordpress/${channel_id}/delete_page/${page_id}`);
  return response.data;
};

const listWordpressChannelTags = async (channel_id) => {
  const response = await axiosInstance.get(`/social_query/wordpress/${channel_id}/list_tags`);
  return response.data;
};

const listWordpressComChannelTags = async (channel_id) => {
  const response = await axiosInstance.get(`/social_query/wordpress_com/${channel_id}/list_tags`);
  return response.data;
};

const listYoutubeChannels = async () => {
  const response = await axiosInstance.get(`/social_query/youtube/list_channels`);
  return response.data;
};

const videoConvert = async (id) => {

  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

  let startConvertResponse;

  // Retry starting the conversion if the initial POST fails
  while (true) {
    try {
      startConvertResponse = (
        await axiosInstance.post(`/video_process_items`, {
          stored_object_id: id
        })
      ).data;
      break; // Break out of the loop once the conversion starts successfully
    } catch (error) {
      console.error("Error starting video conversion. Retrying in 3 seconds...", error);
      await delay(3000);
    }
  }

  // Polling loop for conversion status
  while (true) {
    try {
      await delay(1000);  // Wait 1 second between checks
      const process = (
        await axiosInstance.get(`/video_process_items/${startConvertResponse._id}`)
      ).data;

      if (process.is_complete) {
        return process;
      }
    } catch (error) {
      console.error("Error checking conversion status. Retrying in 3 seconds...", error);
      await delay(3000);
    }
  }
};


const imageStoreOptimized = async (id, cdnUrlModifiers) => {

  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

  const maxRetries = 100; // Maximum number of retries
  let attempt = 0;
  let params = {id};
  if (cdnUrlModifiers) {
    params.cdnUrlModifiers = cdnUrlModifiers;
  }

  while (attempt < maxRetries) {
    try {
      const data = (
        await axiosInstance.post(
          `/uploadcare/image/store_optimized?${queryString.stringify(params)}`
        )
      ).data;
      return data;
    } catch (error) {
      attempt++;
      if (attempt >= maxRetries) {
        throw new Error(`Failed after ${maxRetries} attempts: ${error.message}`);
      }
      console.log(`Retrying... (${attempt}/${maxRetries})`);
      // Wait for 3 seconds before retrying
      await delay(3000);
    }
  }
};


const getMyProfile = async () => {
  const myProfile = (await axiosInstance.get("/users/my-profile")).data;
  return myProfile;
};

const getMyTeam = async () => {
  const myTeam = (await axiosInstance.get("/users/my-team")).data;
  return myTeam;
};

const updateTeam = async (team_id, payload) => {
  const myTeam = (await axiosInstance.put(`/teams/${team_id}`, payload)).data;
  return myTeam;
};

const changeTeamMemberRole = async (user_id, team_id, user_role) => {
  let payload = {
    user_id: user_id,
    team_id: team_id,
    user_role: user_role
  };
  await axiosInstance.put("/users/change-team-member-role", payload);
};

const getMyTeams = async () => {
  const myTeams = (await axiosInstance.get("/users/my-teams")).data;
  return myTeams;
};

const getMyTeamMembers = async () => {
  const myTeamMembers = (await axiosInstance.get("/users/my-team/members")).data;
  return myTeamMembers;
};

const switchTeam = async (team_id) => {
  await axiosInstance.post("/users/switch-team?team_id=" + team_id);
};

const updateMyProfile = async (payload) => {
  const myProfile = (await axiosInstance.put("/users/my-profile", payload)).data;
  return myProfile;
};

const deleteMyProfileTest = async () => {
  const myProfile = (await axiosInstance.post("/users/my-profile/test")).data;
  return myProfile;
};

const deleteMyProfile = async () => {
  const myProfile = (await axiosInstance.delete("/users/my-profile")).data;
  return myProfile;
};

const removeUserFromTeam = async (user_id) => {
  const params = {
    user_id: user_id
  };
  await axiosInstance.post(`/users/remove-user-from-my-team?${queryString.stringify(params)}`);
};

const leaveMyTeam = async () => {
  await axiosInstance.post(`/users/my-team/leave`);
};

const inviteMember = async (email, teamMemberRights, postToChannelIds) => {
  const payload = {
    email: email,
    rights: teamMemberRights,
    post_to_channel_ids: postToChannelIds
  };
  await axiosInstance.post(`/users/invite-user`, payload);
};

const listMyInvitations = async () => {
  const invitations = (await axiosInstance.get("/users/list-my-invitations")).data;
  return invitations;
};

const acceptInvitation = async (token) => {
  await axiosInstance.post("/users/accept-invitation?token=" + token);
};

const declineInvitation = async (token) => {
  await axiosInstance.post("/users/decline-invitation?token=" + token);
};

const getInvitation = async (token) => {
  return (await axiosInstance.get("/users/invitations/" + token)).data;
};

const updateSubscription = async (priceId) => {
  await axiosInstance.put("/stripe/update_subscription?price_id=" + priceId);
};

const accountUsageFrozen = async () => {
  return (await axiosInstance.get(`/plan_limits/account_usage_frozen`)).data;
};

const hitPlanLimit = async (limitKey, increment) => {
  return (await axiosInstance.get(`/plan_limits/hit_plan_limit?limit_key=${limitKey}&increment=${increment}`)).data;
};

const checkUpdateSubscription = async (targetPriceId) => {
  const payload = {"target_price_id": targetPriceId};
  return (await axiosInstance.post(`/stripe/check_update_subscription`, payload)).data;
};

const aiAssistantComposePostText = async (payload) => {
  return (await axiosInstance.post("/ai_assistant/compose_post_text", payload)).data;
};

const aiAssistantSpellingCheck = async (text) => {
  const payload = {
    "request": text,
  };
  return (await axiosInstance.post("/ai_assistant/spelling_check", payload)).data;
};

const aiAssistantHashtagsSuggest = async (text, imageStoredObjectId) => {
  const payload = {
    "text": text,
    "image_stored_object_id": imageStoredObjectId
  };
  return (await axiosInstance.post("/ai_assistant/hashtags_suggest", payload)).data;
};

const listPinterestBoards = async () => {
  return (await axiosInstance.get(`/social_query/pinterest/boards`)).data;
};

const getPinterestMe = async () => {
  return (await axiosInstance.get(`/social_query/pinterest/me`)).data;
};

const listUploadCareFilesFromGroup = async (group_uuid) => {
  return (await axiosInstance.get(`/uploadcare/files_from_group/${group_uuid}`)).data;
};

const createMedia = async (payload) => {
  const media = (await axiosInstance.post(`/medias`, payload)).data;
  return media;
};

const getMedia = async (mediaId) => {
  const media = (await axiosInstance.get(`/medias/${mediaId}`)).data;
  return media;
};

const deleteMedia = async (mediaId) => {
  const media = (await axiosInstance.delete(`/medias/${mediaId}`)).data;
  return media;
};

const listMedias = async (search) => {
  const medias = (await axiosInstance.get(`/medias?${queryString.stringify(search)}`)).data;
  return medias;
};

const createChannelCat = async (payload) => {
  const channelCat = (await axiosInstance.post(`/channel_cats`, payload)).data;
  return channelCat;
};

const updateChannelCat = async (id, payload) => {
  const channelCat = (await axiosInstance.put(`/channel_cats/${id}`, payload)).data;
  return channelCat;
};

const getChannelCat = async (channelCatId) => {
  const channelCat = (await axiosInstance.get(`/channel_cats/${channelCatId}`)).data;
  return channelCat;
};

const deleteChannelCat = async (channelCatId) => {
  const channelCat = (await axiosInstance.delete(`/channel_cats/${channelCatId}`)).data;
  return channelCat;
};

const listChannelCats = async (search) => {
  const channelCats = (await axiosInstance.get(`/channel_cats?${queryString.stringify(search)}`)).data;
  return channelCats;
};

const createPostTag = async (payload) => {
  const postTag = (await axiosInstance.post(`/post_tags`, payload)).data;
  return postTag;
};

const updatePostTag = async (id, payload) => {
  const postTag = (await axiosInstance.put(`/post_tags/${id}`, payload)).data;
  return postTag;
};

const getPostTag = async (postTagId) => {
  const postTag = (await axiosInstance.get(`/post_tags/${postTagId}`)).data;
  return postTag;
};

const deletePostTag = async (postTagId) => {
  const postTag = (await axiosInstance.delete(`/post_tags/${postTagId}`)).data;
  return postTag;
};

const listPostTags = async (search) => {
  const postTags = (await axiosInstance.get(`/post_tags?${queryString.stringify(search)}`)).data;
  return postTags;
};

const createAPIKey = async (payload) => {
  const APIKey = (await axiosInstance.post(`/api_keys`, payload)).data;
  return APIKey;
};

const updateAPIKey = async (id, payload) => {
  const APIKey = (await axiosInstance.put(`/api_keys/${id}`, payload)).data;
  return APIKey;
};

const getAPIKey = async (APIKeyId) => {
  const APIKey = (await axiosInstance.get(`/api_keys/${APIKeyId}`)).data;
  return APIKey;
};


const deleteAPIKey = async (APIKeyId) => {
  const APIKey = (await axiosInstance.delete(`/api_keys/${APIKeyId}`)).data;
  return APIKey;
};

const listAPIKeys = async (search) => {
  const APIKeys = (await axiosInstance.get(`/api_keys?${queryString.stringify(search)}`)).data;
  return APIKeys;
};

const createAIVoice = async (payload) => {
  const aIVoice = (await axiosInstance.post(`/ai_voices`, payload)).data;
  return aIVoice;
};

const updateAIVoice = async (id, payload) => {
  const aIVoice = (await axiosInstance.put(`/ai_voices/${id}`, payload)).data;
  return aIVoice;
};

const getAIVoice = async (aiVoiceId) => {
  const aIVoice = (await axiosInstance.get(`/ai_voices/${aiVoiceId}`)).data;
  return aIVoice;
};

const deleteAIVoice = async (aiVoiceId) => {
  const aIVoice = (await axiosInstance.delete(`/ai_voices/${aiVoiceId}`)).data;
  return aIVoice;
};

const listAIVoices = async (search) => {
  const aIVoices = (await axiosInstance.get(`/ai_voices?${queryString.stringify(search)}`)).data;
  return aIVoices;
};

const createBGMBucket = async (payload) => {
  const bgmBucket = (await axiosInstance.post(`/bgm_buckets`, payload)).data;
  return bgmBucket;
};

const updateBGMBucket = async (id, payload) => {
  const bgmBucket = (await axiosInstance.put(`/bgm_buckets/${id}`, payload)).data;
  return bgmBucket;
};

const getBGMBucket = async (bgmBucketId) => {
  const bgmBucket = (await axiosInstance.get(`/bgm_buckets/${bgmBucketId}`)).data;
  return bgmBucket;
};

const deleteBGMBucket = async (bgmBucketId) => {
  const bgmBucket = (await axiosInstance.delete(`/bgm_buckets/${bgmBucketId}`)).data;
  return bgmBucket;
};

const listBGMBuckets = async (search) => {
  const bgmBuckets = (await axiosInstance.get(`/bgm_buckets?${queryString.stringify(search)}`)).data;
  return bgmBuckets;
};


const createAIKnowledgeBase = async (payload) => {
  const aiKnowledgeBase = (await axiosInstance.post(`/knowledge_bases`, payload)).data;
  return aiKnowledgeBase;
};

const updateAIKnowledgeBase = async (id, payload) => {
  const aiKnowledgeBase = (await axiosInstance.put(`/knowledge_bases/${id}`, payload)).data;
  return aiKnowledgeBase;
};

const getAIKnowledgeBase = async (aiKnowledgeBaseId) => {
  const aiKnowledgeBase = (await axiosInstance.get(`/knowledge_bases/${aiKnowledgeBaseId}`)).data;
  return aiKnowledgeBase;
};

const deleteAIKnowledgeBase = async (aiKnowledgeBaseId) => {
  const aiKnowledgeBase = (await axiosInstance.delete(`/knowledge_bases/${aiKnowledgeBaseId}`)).data;
  return aiKnowledgeBase;
};

const listAIKnowledgeBases = async (search) => {
  const aiKnowledgeBase = (await axiosInstance.get(`/knowledge_bases?${queryString.stringify(search)}`)).data;
  return aiKnowledgeBase;
};

const createAIKnowledgeBaseItem = async (payload) => {
  const aiKnowledgeBaseItem = (await axiosInstance.post(`/knowledge_base_items`, payload)).data;
  return aiKnowledgeBaseItem;
};

const updateAIKnowledgeBaseItem = async (id, payload) => {
  const aiKnowledgeBaseItem = (await axiosInstance.put(`/knowledge_base_items/${id}`, payload)).data;
  return aiKnowledgeBaseItem;
};

const getAIKnowledgeBaseItem = async (aiKnowledgeBaseItemId) => {
  const aiKnowledgeBaseItem = (await axiosInstance.get(`/knowledge_base_items/${aiKnowledgeBaseItemId}`)).data;
  return aiKnowledgeBaseItem;
};

const deleteAIKnowledgeBaseItem = async (aiKnowledgeBaseItemId) => {
  const aiKnowledgeBaseItem = (await axiosInstance.delete(`/knowledge_base_items/${aiKnowledgeBaseItemId}`)).data;
  return aiKnowledgeBaseItem;
};

const listAIKnowledgeBaseItems = async (search) => {
  const aiKnowledgeBaseItem = (await axiosInstance.get(`/knowledge_base_items?${queryString.stringify(search)}`)).data;
  return aiKnowledgeBaseItem;
};

const createShortCode = async (payload) => {
  const shortCode = (await axiosInstance.post(`/shortcodes`, payload)).data;
  return shortCode;
};

const updateShortCode = async (id, payload) => {
  const shortCode = (await axiosInstance.put(`/shortcodes/${id}`, payload)).data;
  return shortCode;
};

const getShortCode = async (shortCodeId) => {
  const shortCode = (await axiosInstance.get(`/shortcodes/${shortCodeId}`)).data;
  return shortCode;
};

const deleteShortCode = async (shortCodeId) => {
  const shortCode = (await axiosInstance.delete(`/shortcodes/${shortCodeId}`)).data;
  return shortCode;
};

const listShortCodes = async (search) => {
  const shortCodes = (await axiosInstance.get(`/shortcodes?${queryString.stringify(search)}`)).data;
  return shortCodes;
};

const createPostHashtagGroup = async (payload) => {
  const postHashtagGroup = (await axiosInstance.post(`/post_hashtag_groups`, payload)).data;
  return postHashtagGroup;
};

const updatePostHashtagGroup = async (id, payload) => {
  const postHashtagGroup = (await axiosInstance.put(`/post_hashtag_groups/${id}`, payload)).data;
  return postHashtagGroup;
};

const getPostHashtagGroup = async (postHashtagGroupId) => {
  const postHashtagGroup = (await axiosInstance.get(`/post_hashtag_groups/${postHashtagGroupId}`)).data;
  return postHashtagGroup;
};

const deletePostHashtagGroup = async (postHashtagGroupId) => {
  const postHashtagGroup = (await axiosInstance.delete(`/post_hashtag_groups/${postHashtagGroupId}`)).data;
  return postHashtagGroup;
};

const listPostHashtagGroups = async (search) => {
  const postHashtagGroups = (await axiosInstance.get(`/post_hashtag_groups?${queryString.stringify(search)}`)).data;
  return postHashtagGroups;
};

const createContact = async (payload) => {
  const contact = (await axiosInstance.post(`/contacts`, payload)).data;
  return contact;
};

const updateContact = async (id, payload) => {
  const contact = (await axiosInstance.put(`/contacts/${id}`, payload)).data;
  return contact;
};

const getContact = async (contactId) => {
  const contact = (await axiosInstance.get(`/contacts/${contactId}`)).data;
  return contact;
};

const deleteContact = async (contactId) => {
  const contact = (await axiosInstance.delete(`/contacts/${contactId}`)).data;
  return contact;
};

const listContacts = async (search) => {
  const contacts = (await axiosInstance.get(`/contacts?${queryString.stringify(search)}`)).data;
  return contacts;
};

const listScheduledPostDiscussionItems = async (search) => {
  const postTags = (await axiosInstance.get(`/scheduled_post_discussion_items?${queryString.stringify(search)}`)).data;
  return postTags;
};

const createScheduledPostDiscussionItem = async (payload) => {
  const scheduledPostDiscussionItem = (await axiosInstance.post(`/scheduled_post_discussion_items`, payload)).data;
  return scheduledPostDiscussionItem;
};

const updateScheduledPostDiscussionItem = async (id, payload) => {
  const scheduledPostDiscussionItem = (await axiosInstance.put(`/scheduled_post_discussion_items/${id}`, payload)).data;
  return scheduledPostDiscussionItem;
};

const getScheduledPostDiscussionItem = async (scheduledPostDiscussionItemId) => {
  const scheduledPostDiscussionItem = (await axiosInstance.get(`/scheduled_post_discussion_items/${scheduledPostDiscussionItemId}`)).data;
  return scheduledPostDiscussionItem;
};

const deleteScheduledPostDiscussionItem = async (scheduledPostDiscussionItemId) => {
  const scheduledPostDiscussionItem = (await axiosInstance.delete(`/scheduled_post_discussion_items/${scheduledPostDiscussionItemId}`)).data;
  return scheduledPostDiscussionItem;
};

const createPostCollection = async (payload) => {
  const postCollection = (await axiosInstance.post(`/post_collections`, payload)).data;
  return postCollection;
};

const updatePostCollection = async (id, payload) => {
  const postCollection = (await axiosInstance.put(`/post_collections/${id}`, payload)).data;
  return postCollection;
};

const getPostCollection = async (postCollectionId) => {
  const postCollection = (await axiosInstance.get(`/post_collections/${postCollectionId}`)).data;
  return postCollection;
};

const deletePostCollection = async (postCollectionId) => {
  const postCollection = (await axiosInstance.delete(`/post_collections/${postCollectionId}`)).data;
  return postCollection;
};

const listPostCollections = async (search) => {
  const postCollections = (await axiosInstance.get(`/post_collections?${queryString.stringify(search)}`)).data;
  return postCollections;
};

const createShortUrl = async (originalUrl) => {
  const shorturl = (await axiosInstance.post(`/shorturls`, {original_url: originalUrl})).data;
  return shorturl;
};

const updateShortUrl = async (id, payload) => {
  const shorturl = (await axiosInstance.put(`/shorturls/${id}`, payload)).data;
  return shorturl;
};

const deleteShortUrl = async (id) => {
  const shorturl = (await axiosInstance.delete(`/shorturls/${id}`)).data;
  return shorturl;
};

const getTiktokCreatorInfo = async (channelId) => {
  const data = (await axiosInstance.get(`/social_query/tiktok/${channelId}/creator_info`)).data;
  return data;
};

const getStoredObject = async (storedObjectId) => {
  try {
    const response = await axiosInstance.get(`/stored_objects/${storedObjectId}`);
    return response.data;
  } catch (e) {
    return;
  }
};

const createAutomation = async (payload) => {
  const automation = (await axiosInstance.post(`/automations`, payload)).data;
  return automation;
};

const updateAutomation = async (id, payload) => {
  const automation = (await axiosInstance.put(`/automations/${id}`, payload)).data;
  return automation;
};

const getAutomation = async (automationId) => {
  const automation = (await axiosInstance.get(`/automations/${automationId}`)).data;
  return automation;
};

const deleteAutomation = async (automationId) => {
  const automation = (await axiosInstance.delete(`/automations/${automationId}`)).data;
  return automation;
};

const listAutomations = async (search) => {
  const automations = (await axiosInstance.get(`/automations?${queryString.stringify(search)}`)).data;
  return automations;
};

const rssImportAutomation = async (id, payload) => {
  const automations = (await axiosInstance.post(`/automations/${id}/rss_import`, payload)).data;
  return automations;
};

const createRSSFeed = async (payload) => {
  const response = await axiosInstance.post("/rss_feeds", payload);
  return response.data;
};

const updateRSSFeed = async (id, payload) => {
  const rssFeed = (await axiosInstance.put(`/rss_feeds/${id}`, payload)).data;
  return rssFeed;
};

const generatePostIdeas = async (payload) => {
  const postIdeas = (await axiosInstance.post(`/post_ideas/generate`, payload)).data;
  return postIdeas;
};

const listPostIdeas = async (search) => {
  const postIdeas = (await axiosInstance.get(`/post_ideas?${queryString.stringify(search)}`)).data;
  return postIdeas;
};

const getPostIdea = async (postIdeaId) => {
  const postIdea = (await axiosInstance.get(`/post_ideas/${postIdeaId}`)).data;
  return postIdea;
};

const deletePostIdea = async (postIdeaId) => {
  const postIdea = (await axiosInstance.delete(`/post_ideas/${postIdeaId}`)).data;
  return postIdea;
};

const listPostIdeaProcesses = async (search) => {
  const postIdeaProcesses = (await axiosInstance.get(`/post_idea_processes?${queryString.stringify(search)}`)).data;
  return postIdeaProcesses;
};

const getPostIdeaProcess = async (postIdeaProcessId) => {
  const postIdeaProcess = (await axiosInstance.get(`/post_idea_processes/${postIdeaProcessId}`)).data;
  return postIdeaProcess;
};

const listTeamMembers = async (search) => {
  const teamMembers = (await axiosInstance.get(`/team_members?${queryString.stringify(search)}`)).data;
  return teamMembers;
};

const updateTeamMember = async (id, payload) => {
  const teamMember = (await axiosInstance.put(`/team_members/${id}`, payload)).data;
  return teamMember;
};

const requestForDraftApproval = async (scheduled_post_id, team_member_ids) => {
  return (await axiosInstance.post(`/scheduled_posts/${scheduled_post_id}/draft_approval`, {team_member_ids: team_member_ids})).data;
};

const requestForDraftApprovalFromExternalEmails = async (scheduled_post_id, emails) => {
  return (await axiosInstance.post(`/scheduled_posts/${scheduled_post_id}/draft_approval_external`, {emails: emails})).data;
};

const draftApprovalTeamMemberEdit = async (scheduled_post_id, state) => {
  return (await axiosInstance.post(`/scheduled_posts/${scheduled_post_id}/draft_approval_team_member_edit`, {state: state})).data;
};

const updateScheduledPostInCollectionOrder = async (scheduled_post_id, shift) => {
  return (await axiosInstance.post(`/scheduled_posts/${scheduled_post_id}/update_scheduled_post_in_collection_order`, {shift: shift})).data;
}

const createCSVPostImportProcess = async (payload) => {
  const csvImportProcess = (await axiosInstance.post(`/csv_post_import_processes`, payload)).data;
  return csvImportProcess;
}

const runCsvPostImportItemsProcessTask = async (id, payload) => {
  const csvImportProcess = (await axiosInstance.post(`/csv_post_import_processes/${id}/run_csv_post_import_items_process_task`, payload)).data;
  return csvImportProcess;
}

const getCSVPostImportProcess = async (id) => {
  const csvImportProcess = (await axiosInstance.get(`/csv_post_import_processes/${id}`)).data;
  return csvImportProcess;
}

const listCSVPostImportProcessItems = async (search) => {
  const csvImportProcessItems = (await axiosInstance.get(`/csv_post_import_process_items?${queryString.stringify(search)}`)).data;
  return csvImportProcessItems;
}


const listShortUrls = async (search) => {
  const shorturls = (await axiosInstance.get(`/shorturls?${queryString.stringify(search)}`)).data;
  return shorturls;
}


const getShortUrl = async (id) => {
  const shorturl = (await axiosInstance.get(`/shorturls/${id}`)).data;
  return shorturl;
}


const getShortUrlClickStats = async (id, fromDate, toDate, uniqueVisitors) => {
  const params = {
    from_date: fromDate,
    to_date: toDate,
    unique_visitors: uniqueVisitors,
  }
  const stats = (await axiosInstance.get(`/shorturls/${id}/clicks-stats?${queryString.stringify(params)}`)).data;
  return stats;
}

const sendContactMessage = async (payload) => {
  const data = (await axiosInstance.post(`/contact/send_internal`, payload)).data;
  return data;
}

const listGeneratedVideos = async (search) => {
  const generatedVideos = (await axiosInstance.get(`/generated_videos?${queryString.stringify(search)}`)).data;
  return generatedVideos;
};

const getGeneratedVideo = async (generatedVideoId) => {
  const generatedVideo = (await axiosInstance.get(`/generated_videos/${generatedVideoId}`)).data;
  return generatedVideo;
};

const deleteGeneratedVideo = async (generatedVideoId) => {
  const generatedVideo = (await axiosInstance.delete(`/generated_videos/${generatedVideoId}`)).data;
  return generatedVideo;
};

const createGeneratedFacelessVideoSeries = async (payload) => {
  const generatedFacelessVideoSeries = (await axiosInstance.post(`/generated_faceless_video_series`, payload)).data;
  return generatedFacelessVideoSeries;
};

const generateGeneratedFacelessVideoSeries = async (id, payload) => {
  const data = (await axiosInstance.post(`/generated_faceless_video_series/${id}/generate`, payload)).data;
  return data;
};

const updateGeneratedFacelessVideoSeries = async (id, payload) => {
  const generatedFacelessVideoSeries = (await axiosInstance.put(`/generated_faceless_video_series/${id}`, payload)).data;
  return generatedFacelessVideoSeries;
};

const deleteGeneratedFacelessVideoSeries = async (generatedFacelessVideoSeriesId) => {
  const generatedFacelessVideoSeries = (await axiosInstance.delete(`/generated_faceless_video_series/${generatedFacelessVideoSeriesId}`)).data;
  return generatedFacelessVideoSeries;
};

const listGeneratedFacelessVideoSeries = async (search) => {
  const facelessShortProcesses = (await axiosInstance.get(`/generated_faceless_video_series?${queryString.stringify(search)}`)).data;
  return facelessShortProcesses;
};

const getGeneratedFacelessVideoSeries = async (facelessShortProcessId) => {
  const facelessShortProcess = (await axiosInstance.get(`/generated_faceless_video_series/${facelessShortProcessId}`)).data;
  return facelessShortProcess;
};

const listGeneratedFacelessVideoProcesses = async (search) => {
  const facelessShortProcesses = (await axiosInstance.get(`/generated_faceless_video_processes?${queryString.stringify(search)}`)).data;
  return facelessShortProcesses;
};

const getGeneratedFacelessVideoProcess = async (facelessShortProcessId) => {
  const facelessShortProcess = (await axiosInstance.get(`/generated_faceless_video_processes/${facelessShortProcessId}`)).data;
  return facelessShortProcess;
};

const getGeneratedFacelessVideoSeriesContentType = async () => {
  const data = (await axiosInstance.get(`/constants/generated_faceless_video_series_content_type`)).data;
  return data;
};

const getGeneratedFacelessVideoStyles = async () => {
  const data = (await axiosInstance.get(`/constants/generated_faceless_video_styles`)).data;
  return data;
};

const listDefaultAIVoices = async () => {
  const data = (await axiosInstance.get(`/constants/ai_voices`)).data;
  return data;
};

const getTelegramChannelIdFromInviteLink = async (inviteLink) => {
  const data = (await axiosNode1Instance.get(`/social_query/telegram/channel_id_from_invite_link?invite_link=${inviteLink}`)).data;
  return data;
}

const postPreviewRendererTextRender = async (text, channel_id) => {
  const payload = {
    text: text,
    channel_id: channel_id,
  }
  const data = (await axiosInstance.post(`/post_preview_renderer/text_render`, payload)).data;
  return data;
}

const getTeam = async (id) => {
  const data = (await axiosInstance.get(`/teams/${id}`)).data;
  return data;
}

const deleteTeam = async (id) => {
  const data = (await axiosInstance.delete(`/teams/${id}`)).data;
  return data;
}

const listWhitelabeledTeams = async (search) => {
  const data = (await axiosInstance.get(`/teams/white_labeled/list?${queryString.stringify(search)}`)).data;
  return data;
}

const updateWhiteLabeledTeam = async (id, payload) => {
  const data = (await axiosInstance.put(`/teams/white_labeled/${id}/update`, payload)).data;
  return data;
}

const getTeamLogos = async (slug) => {
  const data = (await axiosInstance.get(`/teams/${slug}/icons`)).data;
  return data;
}

const createWhiteLabeledTeam = async (payload) => {
  const data = (await axiosInstance.post(`/teams/white_labeled/create`, payload)).data;
  return data;
}

const duplicatePost = async (id) => {
  const data = (await axiosInstance.post(`/scheduled_posts/${id}/clone`)).data;
  return data;
}

const phoneNumberSendConfirmCode = async (phoneNumber) => {
  const data = (await axiosInstance.post(`/phone_number_confirm_codes/send_confirm_code`, {phone_number: phoneNumber})).data;
  return data;
}

const phoneNumberValidateConfirmCode = async (phoneNumber, confirmCode) => {
  const data = (await axiosInstance.post(`/phone_number_confirm_codes/validate_confirm_code`, {
    phone_number: phoneNumber,
    confirm_code: confirmCode
  })).data;
  return data;
}

const getPlanUsage = async () => {
  const data = (await axiosInstance.get(`/stripe/plan_usage`)).data;
  return data;
}

const getCanvaAccountConnectionStatus = async () => {
  const data = (await axiosInstance.get(`/canva/canva_account_connection_status`)).data;
  return data;
}

const getCanvaLoginURL = async (query) => {
  const data = (await axiosInstance.get(`/canva/get_login_url?${queryString.stringify(query)}`)).data;
  return data;
}

const listCanvaUserDesigns = async (query) => {
  const data = (await axiosInstance.get(`/canva/designs?${queryString.stringify(query)}`)).data;
  return data;
}

const startCanvaExport = async (query) => {
  const data = (await axiosInstance.post(`/canva/start_export?${queryString.stringify(query)}`)).data;
  return data;
}

const getCanvaImportExportStatus = async (jobId) => {
  const data = (await axiosInstance.get(`/canva/export_status/${jobId}`)).data;
  return data;
}

const generateAIImage = async (inputQuery, count, aiImageModel, inputType) => {
  const data = (await axiosInstance.post(`/ai_assistant/generate_images`, {
    input_query: inputQuery,
    count: count,
    ai_image_model: aiImageModel,
    input_type: inputType,
  })).data;
  return data;
}

const requestEmailLoginLink = async (email, domain, redirect_url) => {
  const data = (await axiosInstance.post(`/users/email-login-link`, {
    email: email,
    domain: domain,
    redirect_url: redirect_url
  })).data;
  return data;
}

const requestEmailLoginLinkImpersonate = async (target_user_id, domain) => {
  const data = (await axiosInstance.post(`/users/email-login-link-impersonate`, {
    target_user_id: target_user_id,
    domain: domain,
  })).data;
  return data;
}

const addChannelsToScheduledPosts = async (scheduledPostIds, channelIds) => {
  // Prepare payload according to the ScheduledPostAddChannelsMultiPayload model:
  // { scheduled_post_ids: [..], channel_ids: [..] }
  const payload = {
    scheduled_post_ids: scheduledPostIds,
    channel_ids: channelIds,
  };
  await axiosInstance.post("/scheduled_posts/add_channels", payload);
};

const removeChannelsFromScheduledPosts = async (scheduledPostIds, channelIdsArray) => {
  // Transform the array of channel IDs into an object where keys are the channel IDs.
  // The API only uses the keys to determine which channels to remove.
  const channelIdsObj = {};
  channelIdsArray.forEach((id) => {
    channelIdsObj[id] = true;
  });
  const payload = {
    scheduled_post_ids: scheduledPostIds,
    channel_ids: channelIdsObj,
  };
  await axiosInstance.post("/scheduled_posts/remove_channels", payload);
};

const uploadFileToUploadcare = async (file) => {
  // Create a FormData instance and append the file (ensure the key "file" matches the server expectation)
  const formData = new FormData();
  formData.append("file", file);

  // Post the FormData to the /uploadcare/upload route with appropriate headers
  const response = await axiosInstance.post("/uploadcare/upload", formData, {
    headers: {"Content-Type": "multipart/form-data"}
  });

  return response.data;
};


export {
  addChannelsToScheduledPosts,
  removeChannelsFromScheduledPosts,
  listFBGroups,
  listFBPages,
  listInstagramAccounts,
  listLinkedinPages,
  addChannel,
  updateChannel,
  getChannel,
  deleteChannel,
  listChannels,
  addChannelWordpress,
  addScheduledPost,
  videoConvert,
  listScheduledPosts,
  countScheduledPosts,
  deleteScheduledPost,
  getMyProfile,
  updateMyProfile,
  deleteMyProfile,
  deleteMyProfileTest,
  getMyTeam,
  updateTeam,
  getMyTeamMembers,
  changeTeamMemberRole,
  removeUserFromTeam,
  leaveMyTeam,
  inviteMember,
  listMyInvitations,
  acceptInvitation,
  declineInvitation,
  getInvitation,
  getMyTeams,
  switchTeam,
  updateSubscription,
  hitPlanLimit,
  accountUsageFrozen,
  checkUpdateSubscription,
  aiAssistantComposePostText,
  getScheduledPostById,
  updateScheduledPost,
  listPinterestBoards,
  getPinterestMe,
  listUploadCareFilesFromGroup,
  listWordpressChannelCategories,
  listYoutubeChannels,
  listWordpressChannelPages,
  listWordpressChannelPosts,
  listWordpressChannelTags,
  getWordpressChannelPost,
  getWordpressChannelPage,
  getWordpressChannelMedia,
  updateWordpressChannelPost,
  updateWordpressChannelPage,
  deleteWordpressChannelPost,
  deleteWordpressChannelPage,
  previewWordpressChannelPost,
  previewWordpressChannelPage,
  listMedias,
  createMedia,
  deleteMedia,
  getMedia,
  createShortUrl,
  getTiktokCreatorInfo,
  listGMBLocations,
  createChannelCat,
  updateChannelCat,
  getChannelCat,
  deleteChannelCat,
  listChannelCats,
  createPostTag,
  updatePostTag,
  getPostTag,
  deletePostTag,
  listPostTags,
  createAIVoice,
  updateAIVoice,
  getAIVoice,
  deleteAIVoice,
  listAIVoices,
  createAIKnowledgeBase,
  updateAIKnowledgeBase,
  getAIKnowledgeBase,
  deleteAIKnowledgeBase,
  listAIKnowledgeBases,
  createAIKnowledgeBaseItem,
  updateAIKnowledgeBaseItem,
  getAIKnowledgeBaseItem,
  deleteAIKnowledgeBaseItem,
  listAIKnowledgeBaseItems,
  createShortCode,
  updateShortCode,
  getShortCode,
  deleteShortCode,
  listShortCodes,
  createPostHashtagGroup,
  updatePostHashtagGroup,
  getPostHashtagGroup,
  deletePostHashtagGroup,
  listPostHashtagGroups,
  createContact,
  updateContact,
  getContact,
  deleteContact,
  listContacts,
  createPostCollection,
  updatePostCollection,
  getPostCollection,
  deletePostCollection,
  listPostCollections,
  getStoredObject,
  listAutomations,
  createAutomation,
  updateAutomation,
  deleteAutomation,
  getAutomation,
  rssImportAutomation,
  createRSSFeed,
  updateRSSFeed,
  deleteScheduledPosts,
  copyScheduledPostsToCollection,
  copyScheduledPostsToDraft,
  setScheduledPostsSchedule,
  generatePostIdeas,
  listPostIdeas,
  getPostIdea,
  deletePostIdea,
  listPostIdeaProcesses,
  getPostIdeaProcess,
  listScheduledPostDiscussionItems,
  createScheduledPostDiscussionItem,
  updateScheduledPostDiscussionItem,
  getScheduledPostDiscussionItem,
  deleteScheduledPostDiscussionItem,
  updateTeamMember,
  listTeamMembers,
  requestForDraftApproval,
  requestForDraftApprovalFromExternalEmails,
  draftApprovalTeamMemberEdit,
  updateScheduledPostInCollectionOrder,
  createCSVPostImportProcess,
  getCSVPostImportProcess,
  listCSVPostImportProcessItems,
  runCsvPostImportItemsProcessTask,
  listShortUrls,
  getShortUrl,
  getShortUrlClickStats,
  aiAssistantSpellingCheck,
  imageStoreOptimized,
  sendContactMessage,
  aiAssistantHashtagsSuggest,
  listGeneratedVideos,
  getGeneratedVideo,
  deleteGeneratedVideo,
  createGeneratedFacelessVideoSeries,
  updateGeneratedFacelessVideoSeries,
  deleteGeneratedFacelessVideoSeries,
  listGeneratedFacelessVideoSeries,
  getGeneratedFacelessVideoSeries,
  getGeneratedFacelessVideoSeriesContentType,
  getGeneratedFacelessVideoStyles,
  listDefaultAIVoices,
  listGeneratedFacelessVideoProcesses,
  getGeneratedFacelessVideoProcess,
  generateGeneratedFacelessVideoSeries,
  getTelegramChannelIdFromInviteLink,
  postPreviewRendererTextRender,
  getTeam,
  deleteTeam,
  listWhitelabeledTeams,
  updateWhiteLabeledTeam,
  createWhiteLabeledTeam,
  getTeamLogos,
  duplicatePost,
  phoneNumberSendConfirmCode,
  phoneNumberValidateConfirmCode,
  getPlanUsage,
  updateShortUrl,
  deleteShortUrl,
  getCanvaAccountConnectionStatus,
  getCanvaLoginURL,
  listCanvaUserDesigns,
  startCanvaExport,
  getCanvaImportExportStatus,
  addChannelBluesky,
  generateAIImage,
  requestEmailLoginLink,
  listWpComSites,
  listWordpressComChannelCategories,
  listWordpressComChannelTags,
  listWordpressComChannelPages,
  requestEmailLoginLinkImpersonate,
  updateScheduledPostV2,
  createBGMBucket,
  updateBGMBucket,
  listBGMBuckets,
  getBGMBucket,
  deleteBGMBucket,
  uploadFileToUploadcare,
  createAPIKey,
  deleteAPIKey,
  listAPIKeys,
  updateAPIKey,
  getAPIKey,
};
